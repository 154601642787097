import React, { Component } from 'react';
import Axios from 'axios';
import Spinner from '../../spinner/Spinner';
import Header from "components/Headers/Header.js";
import {
    Button,
    Card,
    CardTitle,
    CardBody,
    CardText,
    Modal,
    Form,
    FormGroup,
    Row,
    Col,
    Input,
    Container,

} from "reactstrap"



export default class ViewCommission extends Component {
    constructor(props) {
        super(props)

        //state elements
        this.state = {
            commission: '',
            id: '',
            exampleModal: false,
            loading: false,
        }
    }

    toggleModal = state => {
        this.setState({
            [state]: !this.state[state]
        });
    };

    async componentDidMount() {
        //set loading to true to show spinner
        this.setState({ loading: true })

        if (!window.localStorage.getItem("token")) {
            //redirect to Login
            this.props.history.push("/");
        }

        let token = JSON.parse(window.localStorage.getItem("token"));
        let alteredToken = token.substr(7);

        let url = "https://smow-dev.herokuapp.com";

        const authAxios = Axios.create({
            baseURL: url,
            headers: {
                Authorization: `Bearer ${alteredToken}`,

            },
        });

        await authAxios.get('/admin/commission')
            .then((response) => {
                this.setState({ commission: response.data.value, id: response.data._id, loading: false })

            })
            .catch();


    }

    handleCommissionChange = (e) => {
        this.setState({ commission: e.target.value })
    }

    handleSubmit = (e) => {

        let data = {
            "commission": this.state.commission
        }

        let token = JSON.parse(window.localStorage.getItem("token"));
        let alteredToken = token.substr(7);

        let url = "https://smow-dev.herokuapp.com";

        const authAxios = Axios.create({
            baseURL: url,
            headers: {
                Authorization: `Bearer ${alteredToken}`,

            },
        });

        authAxios.put(`/admin/commission/${this.state.id}`, data)
            .then((response) => {
                alert("commission updated successfully!")

            })
            .catch(error => {
                alert(error)
                this.setState({ loading: false })
            });

        this.toggleModal("exampleModal");

        e.preventDefault();
    }


    render() {
        if (this.state.loading) {
            return (
                <Spinner />
            )
        } else {
            return (

                <>
                    <Header />
                    <Container className="mt--7" fluid>
                        <Row>
                            <Col className="mb-5 mb-xl-0" xl="8">
                                <Card className="shadow">

                                    <CardBody>
                                        <CardTitle>Commission</CardTitle>
                                        <CardText>
                                            Commission : <span className="h2"> {this?.state?.commission} </span>
                                        </CardText>
                                        <Button
                                            color="default"
                                            onClick={() => this.toggleModal("exampleModal")}
                                        >
                                            Update commission
                                       </Button>
                                    </CardBody>
                                </Card>

                                <Modal
                                    className="modal-dialog-centered"
                                    isOpen={this.state.exampleModal}
                                    toggle={() => this.toggleModal("exampleModal")}
                                >
                                    <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">
                                            Update Commission
                                        </h5>
                                        <button
                                            aria-label="Close"
                                            className="close"
                                            data-dismiss="modal"
                                            type="button"
                                            onClick={() => this.toggleModal("exampleModal")}
                                        >
                                            <span aria-hidden={true}>×</span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <Form role="form" onSubmit={this.handleSubmit}>

                                            <Row>
                                                <Col md="7">
                                                    <FormGroup>
                                                        <label>Commission</label>
                                                        <Input
                                                            id="updated_commission"
                                                            placeholder="Enter Commission..."
                                                            type="text"
                                                            value={this.state.commission}
                                                            onChange={this.handleCommissionChange}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                            </Row>
                                            <Button color="primary">Update Commission</Button>
                                        </Form>
                                    </div>
                                    <div className="modal-footer">
                                        <Button
                                            color="secondary"
                                            data-dismiss="modal"
                                            type="button"
                                            onClick={() => this.toggleModal("exampleModal")}
                                        >
                                            Close
                                     </Button>

                                    </div>
                                </Modal>
                            </Col>
                        </Row>
                    </Container>
                </>

            )
        }
    }
}
