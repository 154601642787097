import React, { Component } from 'react';
import Axios from 'axios';
import Spinner from '../../spinner/Spinner';
import {
    FormGroup,
    Form,
    Input,
    Card,
    CardBody,
    CardTitle,
    Row,
    Col,
    Button,
    Container
} from "reactstrap";
import Header from "components/Headers/Header.js";


export default class AddServiceCategory extends Component {
    constructor(props) {
        super(props)

        //state elements
        this.state = {
            name: '',
            loading: false,
        }
    }

    //Set name state on change 
    handlenameChange = event => {
        this.setState({
            name: event.target.value
        })
    }

    //logout
    logout = () => {
        //remove token from local storage
        window.localStorage.removeItem("token");
        //redirect to login page
        this.props.history.push("/");
    }

    componentDidMount() {
        //get token from local storage
        if (!window.localStorage.getItem("token")) {
            //redirect to Login
            this.props.history.push("/");
        }
    }

    handleSubmit = (event) => {
        //set loading to true to show spinner
        this.setState({ loading: true })
        //get token from local storage
        let token = JSON.parse(window.localStorage.getItem("token"));
        //Strip of Bearer and space from token
        let alteredToken = token.substr(7);

        //Get Url
        let id = window.location.href;
        //COnvert url into array
        let arr = id.split('/');
        //Get the Category Id from Url
        let actualId = arr[5];

        //base url
        let url = "https://smow-dev.herokuapp.com";
        //form-data
        let data = {
            "category": actualId,
            "name": this.state.name
        };

        //create axios instance
        const authAxios = Axios.create({
            baseURL: url,
            headers: {
                Authorization: `Bearer ${alteredToken}`,

            },
        });


        //Make API Call
        authAxios.post('/admin/servicecategory', data)

            .then((response) => {

                if (response.data.success) {
                    //set loading to false
                    this.setState({ loading: false })
                    window.location.href = "/admin/list-service-category"
                }

            }).catch(error => {
                alert("Failed!")
                this.setState({ loading: false })
            });

        event.preventDefault();
    }

    render() {
        const { name } = this.state;
        if (this.state.loading) {
            return (
                <Spinner />
            )
        } else {

            return (
                <>
                    <Header />
                    <Container className="mt--7" fluid>
                        <Row>
                            <Col>
                                <Card className="shadow">
                                    <CardBody>
                                        <Form role="form" onSubmit={this.handleSubmit}>
                                            <CardTitle>Add Service Category</CardTitle>
                                            <Row>
                                                <Col md="7">
                                                    <FormGroup>
                                                        <label>Service Category Name</label>
                                                        <Input
                                                            id="category_name"
                                                            placeholder="Enter Category Name..."
                                                            type="text"
                                                            value={name}
                                                            onChange={this.handlenameChange}
                                                        />
                                                    </FormGroup>
                                                </Col>

                                            </Row>
                                            <Button color="primary">ADD Service Category</Button>
                                        </Form>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </>
            )
        }
    }
}
