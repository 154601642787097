import React, { Fragment } from 'react';
import spinner from '../spinner/spinner.gif';

const style = {
    width: "40px",
    marginTop: "20%",
    marginBottom: "20%",

}


const LoginSpinner = () => <Fragment>
    <img src={spinner} alt="Loading..." style={style} />
</Fragment>



export default LoginSpinner;