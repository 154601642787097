import React, { Fragment } from 'react';
import spinner from '../spinner/spinner.gif';

const style = {
    width: "40px",
    marginTop: "25%",
    marginLeft: "45%",
    marginBottom: "20%",

}

const Spinner = () => <Fragment>
    <img src={spinner} alt="Loading..." style={style} />
</Fragment>



export default Spinner;